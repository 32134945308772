import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image';
import { Truncate } from '../../utils/General';

const TestimonialCard = ({author, quote}) => {

    const [expanded, setExpanded ] = React.useState(false)

    const text =  Truncate(quote.text, 300)

    const needToExpand = text.length < quote.text.length

    return (
        <div className="flex w-full flex-col bg-white justify-between  px-6 py-4 shadow-md rounded">
            <div className="flex flex-1 border-l-2 border-primary pl-3 py-2">
                <h4 className="mb-0 text-sm md:text-base font-normal self-center text-left mb-2 relative ">
                <i className="absolute top-0 text-6xl fa fa-quote-left z-10 text-gray-700 opacity-25 -ml-6 -mt-4"></i>
                { expanded ? quote.text : Truncate(quote.text, 300) } { needToExpand && !expanded &&  <button onClick={() => setExpanded(true)}className="text-xs text-primary hover:font-semibold">Read more</button>}</h4>
            </div>
            <div className="flex justify-end">
                
                <p className="text-sm md:text-base self-center mr-2 ">{author.name}</p>
                <Img fluid={author.photo.fluid} className="w-12 h-12 rounded-full" />
            </div>
        </div>
    )
}

TestimonialCard.propTypes = {

}

export default TestimonialCard
