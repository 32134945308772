import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const UniversityCard = ({ className, ...rest }) => {
    return (
        <div className={`p-2 box-border mx-auto ${className}`}>
            <div className={`bg-gray-200 p-4 shadow-md `} >
                <Img fluid={rest.fluid} />
            </div>

        </div>

    )
}

UniversityCard.defaultProps = {

}

UniversityCard.propTypes = {

}

export default UniversityCard
