import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import Linked from '../components/elements/Linked';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import UniversityCard from '../components/cards/UniversityCard';
import TestimonialCard from "../components/cards/TestimonialCard";
import BlogPost from '../components/cards/BlogPost';
import FAQ from '../components/lists/FAQ';
import { BOOK_URL } from "../data/constants";


const IndexPage = ({ data }) => {

  const { bookCover, universities, middleCover, testimonials, popularPosts, faq } = data;

  return (
    <>
      <SEO title="Write better essays in half the time" />
      <Layout>

        <Circles style={{ left: -300, top: -300, height: 600, width: 600, }} />
        <section style={{ maxHeight: 800 }} className="min-h-screen relative z-10 max-w-screen-lg mx-auto flex flex-col md:flex-row md:-mt-24 px-4">
          <div className="w-full order-2 md:order-1 md:w-1/2 flex flex-col justify-center">
            <p className="text-sm font-normal text-primary py-1 px-3 bg-green-300 rounded-md" style={{width: 'max-content'}}><i className="fa fa-award mr-1 text-gold-dark" />Now in its second edition!</p>
            <h1 className="text-primary  font-normal text-3xl md:text-5xl relative">
            
              Write top quality essays,<br className="block sm:hidden" /> in half the time</h1>
            <p className="md:text-xl leading-loose">Learn the strategies to write academic essays quickly and succinctly. From improving your note-taking to structuring arguments, the Ultimate Essay Guide will help you improve your essay writing and boost your grades.</p>
            <Linked
              linkTo={BOOK_URL}
              className="text-xl w-max-c bg-gradient-l-golden-ripple hover:bg-gradient-l-golden inline-block text-primary rounded py-2 px-3 my-2">
              Get the book now
          </Linked>
          </div>

          <div className="order-1 w-full md:order-2 md:w-1/2 flex">
            <div className="w-full self-center mx-auto">
              <Img className="w-full mt-12 sm:w-3/4 md:w-full mx-auto" fluid={bookCover.fluid} alt={bookCover.title} />

            </div>

          </div>

        </section>


        {/* 
        
        Universities Sections with data coming in from Contentful

        */}

        <section className="text-center max-w-screen-lg mx-auto">
          <h3 className="text-2xl px-4 mt-12">{universities.title || "Used by students from..."}</h3>
          <div className="flex w-full flex-wrap ">
            {universities.logos.map(logo => < UniversityCard key={logo.title} className="w-1/2 md:w-1/3 lg:w-1/5" {...logo} />)}
          </div>
        </section>


        {/* 
        
          Secrets of Essay Writing 
          * All data in array
          
        */}

        <section className="text-center max-w-screen-md mx-auto py-16 md:py-24 px-4">
          <h3>Learn the secrets of effective essay writing</h3>
          <h5 className="text-base md:text-xl">Don't spend 3 years learning how to write.<br className="md:block hidden" /> Kick start your academic success from the beginning of your journey.</h5>

          <div className="grid grid-cols-2 gap-4 md:gap-12 my-12">
            {

              [
                {
                  title: "How to reduce workload and stress",
                  description: "By learning how to write a high-quality essay in half the time, you also halve the stress and workload.",
                  icon: "fa fa-book-reader"
                },
                {
                  title: "How to maintain efficiency when researching",
                  description: "The more effective your research, the higher quality your essay will be. Follow our tips for essay research, and you’ll be well on your way to an essay that impresses!",
                  icon: "fa fa-stopwatch"
                },
                {
                  title: "How to identify the most relevant information",
                  description: "Cut down the unnecessary hours you spend digging for nuggets of gold in the overwhelming amount of information available.",
                  icon: "fa fa-search"
                },
                {
                  title: "Structuring an argument for maximum impact",
                  description: "How to structure your argument to produce the maximum effect upon the reader for those higher-level grades.",
                  icon: "fa fa-stream"
                },


              ].map(({ title, description, icon }) => <div key={title} className="flex w-full flex-col md:flex-row ">
                <div className="">
                  <IconSquare icon={icon} bgColour="bg-gray-300 shadow-md" text="text-5xl text-primary" />
                </div>
                <div className="text-left md:ml-4 self-start w-full my-4 md:my-0">
                  <h3 className="mb-0 text-sm md:text-base font-normal ">{title}</h3>
                  <p className="text-sm md:text-base">{description}</p>
                </div>
              </div>)
            }
          </div>
        </section>

        {/* 
        
          Methods of essay writing  
          * Components contained below
          
        */}
        <section className="text-center max-w-screen-lg mx-auto md:py-24 px-4 flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 mb-4 relative">
            <Circles style={{ height: 300, width: 300, left: 0, top: 0 }} className="mx-auto" icons />
          </div>
          <div className="w-full md:w-1/2 text-left my-4 self-center">
            <h3 className="text-2xl">Once you understand the process, you’ll never view essay writing the same way again.</h3>
            <p>Our formula for essay writing can apply to every essay or paper you’ll need to write. Once you nail it, your essays will practically write themselves. </p>
          </div>
        </section>

        {/* 
        
          Call to action cover
          * Photo on contentful
          
        */}

        <section style={{ height: 600 }} className="w-full relative my-12">
          <Img fluid={middleCover.fluid} className="h-full w-full" />
          <div className="absolute h-full top-0 w-full">
            <div className="grid md:grid-cols-2 text-gray-100 h-full max-w-screen-lg mx-auto">
              <div className="col-start-2 w-full h-full flex" >
                <div className="self-center px-4">
                  <h3 className="md:text-xl text-lg font-medium">Whether you want to raise your grades higher, or simply succeed with less effort, the strategies in this book provide a comprehensive overview of the actions you need to take to succeed.</h3>
                  <Linked
                    linkTo={BOOK_URL}
                    className="text-xl w-max-c bg-gradient-l-golden-ripple hover:bg-gradient-l-golden inline-block text-primary rounded py-2 px-3 my-2">
                    Get the book now
                  </Linked>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* 
        
          Who is this book for section
          * all data in arrays

        */}

        <section className="text-center max-w-screen-lg mx-auto py-16 md:py-24 px-4">
          <h3>Who is this book for?</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-12 my-12">
            {
              [
                {
                  title: "Get ahead of your peers",
                  description: "The secrets for essays that stand out above the rest.",
                  icon: "fa fa-running"
                },
                {
                  title: "Produce essays faster",
                  description: "Write a higher-quality essay, in half the time.",
                  icon: "fa fa-clock"
                },
                {
                  title: "Master convincing arguments",
                  description: "Expertly navigate the clearly-structured and well-developed arguments required for those higher grades.",
                  icon: "fa fa-gavel"
                },
                {
                  title: "Be more efficient",
                  description: "Whether you want more time to write or more time at the pub, get more done in less time.",
                  icon: "fa fa-stopwatch"
                },
                {
                  title: "Understand UK Essay formats",
                  description: "Learn how essays in the UK are constructed and why that is important.",
                  icon: "fa fa-stream",

                },
                {
                  title: "Complete research faster",
                  description: "Required reading takes up over 40% of the time in essay writing. Discover how to cut it down without compromising on quality.",
                  icon: "fa fa-fast-forward"
                },
              ].map(({ title, description, icon }) => <div key={title} className="flex w-full flex-col ">
                <div className="">
                  <IconSquare className="w-12 h-12" text="text-3xl text-gray-100" icon={icon} />
                </div>
                <div className="text-left self-center w-full my-4">
                  <h3 className="mb-0 text-sm md:text-base font-normal ">{title}</h3>
                  <p className="text-sm md:text-base">{description}</p>
                </div>
              </div>)
            }
          </div>
        </section>



        {/* 
        
          Praise for the Ultimate Essay Guide
           * data on contentful (thispersondoesnotexist generated images)
        
        
        */}

        <section className="text-center max-w-screen-lg mx-auto py-16 md:py-24 px-4">
          <h3>{testimonials.title || "Praise for the Ultimate Essay Guide"}</h3>
          <div className="grid md:grid-cols-2 gap-4 md:gap-4 my-12">
            {testimonials.quotes.map(testimonial => <TestimonialCard key={testimonial.author.name} {...testimonial} />)
            }
          </div>
          <Linked
            linkTo={BOOK_URL}
            className="text-xl w-max-c bg-gradient-l-golden-ripple hover:bg-gradient-l-golden inline-block text-primary rounded py-2 px-3 my-2">
            Get the book now
          </Linked>
          <small className="block max-w-xs mx-auto text-xs">Want to have your testimonial to appear here? Reach out to us at <a className="font-normal" href="mailto:hello@theultimateessayguide.com" >hello@theultimateessayguide.com</a> and let us know how the book has helped you!</small>
        </section>



        {/* 
        
          Blog Articles Section 
            * Pulled from contentful, will pull top 3 popular posts
        
        */}

        <section className="max-w-screen-lg mx-auto px-4" >
          <h3 className="text-center mb-1">Articles from our Blog</h3>
          <p className="max-w-xl mx-auto text-center mb-6">For the latest student news or study hacks check out our blog.</p>
          <div className="grid md:grid-cols-3 gap-4">
            {popularPosts && popularPosts.posts.map(((post, index) => <BlogPost key={post.slug + index} {...post} />))}
          </div>
        </section>



        {/* 
        
          FAQ
            * Questions pulled from Contentful
        
        */}

        <section className="max-w-screen-lg mx-auto px-4 py-16 px-4 sm:px-6 lg:px-8" >
          <h3 className="text-center mb-1">{faq.title || "Frequently asked questions"}</h3>
         <FAQ questions={faq.questions} />
        </section>


      </Layout>
    </>
  )
}

export const IconSquare = ({ icon, className = "w-24 h-24 md:h-32 md:w-32", text = "text-5xl text-gray-100", bgColour, style }) =>
  <div style={style} className={`${bgColour ? bgColour : "bg-primary"} rounded flex ${className}`}>
    <i className={`${icon} ${text} mx-auto self-center`}></i>
  </div>

export const Circles = ({ style = { left: -300, top: -300, height: 600, width: 600, }, className = "absolute", icons = false }) => <div
  style={{ boxShadow: '0 0 30px 0 rgba(0,0,0,0.50)', ...style }}
  className={` bg-gray-100 rounded-full flex ${className}`}>
  <div className=" w-full flex">

    <div
      style={{ height: style.height - 100, width: style.width - 100, boxShadow: '0 0 30px 0 rgba(0,0,0,0.50)' }}
      className=" bg-gray-100 rounded-full self-center mx-auto relative">
      {icons &&
        <>
          <IconSquare
            icon="fa fa-search"
            bgColour="bg-gradient-l-golden"
            text="text-primary text-2xl"
            className="absolute top-0 h-16 w-16 "
            style={{ left: ((style.width - 100) / 2) - 32, top: -32 }} />
          <IconSquare
            icon="fa fa-list"
            bgColour="bg-gradient-l-golden"
            text="text-primary text-2xl"
            className="absolute  h-16 w-16 right-0"
            style={{ top: ((style.width - 100) / 2) - 32, right: -32 }} />
          <IconSquare
            icon="fa fa-pen"
            bgColour="bg-gradient-l-golden"
            text="text-primary text-2xl"
            className="absolute bottom-0 left-0 h-16 w-16"
            style={{ left: ((style.width - 100) / 2) - 32, bottom: -32 }} />
          <IconSquare
            icon="fa fa-redo"
            bgColour="bg-gradient-l-golden"
            text="text-primary text-2xl"
            className="absolute h-16 w-16"
            style={{ top: ((style.width - 100) / 2) - 32, left: -32 }} />
        </>}
    </div>

  </div>


</div>

export const IndexQuery = graphql`
query IndexQuery {
  bookCover: contentfulAsset(title: {eq: "The Ultimate Essay Guide Book Cover | Write Better Essays in Half the Time"}) {
    id
    title
    fluid(maxWidth:600, quality: 80) {
      ...GatsbyContentfulFluid_tracedSVG
    }
  }
  universities: contentfulUniversities {
    logos {
      title
      fluid(maxWidth:200, quality:80) {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
    title
  }
  middleCover: contentfulAsset(title: {eq: "Photo by Edwin Andrade on Unsplash"}) {
    id
    title
    fluid(maxWidth: 1000, quality: 90) {
      ...GatsbyContentfulFluid_tracedSVG
    }
  }
  testimonials: contentfulTestimonials {
    title
    quotes {
      id
      quote {
        text: quote
      }
      author {
        photo {
          fluid(maxWidth:50, quality:50) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        name
      }
    }
  }
  popularPosts: contentfulPopularPosts {
    posts {
      published
      readingTime
      slug
      title
      description
      featured
      coverImage {
        fluid(quality: 100, maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      author {
        photo {
          fixed(width: 50, quality: 50) {
            ...GatsbyContentfulFixed_withWebp
          }
          title
        }
        name
      }
    }
  }
  faq: contentfulFaq {
    title
    questions {
      question
      answer {
        text: answer
      }
    }
  }
}

`

export default IndexPage
