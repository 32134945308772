export const bookContents = [
    {
        section: "Section 1: Understanding the Assignment",
        shortSection: "Understanding the assignment",
        icon: "fa fa-book-reader",
        id: "section-1",
        chapters: [
            {
                title: "Chapter 1: Dissecting the Question",
                description: "The first thing to tackle with any essay is the question, whether that is dissecting the one that your professor or teacher has provided or creating your own. The latter is almost always the most challenging. We’ll take you through analysis of the question by looking at sample questions – how you can break them down and go beyond those common terms like “explain”, “critically analyse”, and “explore” and use them to maximum effect. We’ll also look at methods for crafting your own questions and the pitfalls to avoid during the process."
            }, 
            {
                title: "Chapter 2: Effective Research",
                description: "When it comes down to research, being able to access the most relevant and important sources quickly and efficiently is always a priority. No one ever complained about not having to read irrelevant articles or books. To fine tune your research capabilities, we look at practical ways to optimise your use of the library and increase your reading speed as well as systems you can use for scanning text. We’ll also share some of the top online tools and an offline system that helps to prioritise your reading and source the most valuable documents to inform your essay content."
            }   
        ]
    },
    {
        section: "Section 2: Structuring your Content",
        shortSection: "Structuring your content",
        icon: "fa fa-stopwatch",
        id: "section-2",
        chapters: [
            {
                title: "Chapter 3: Taking Useful Notes",
                description: "Being able to research and read lots of relevant sources for your essay topic is next to useless if you can’t find an effective way of storing the information. If you have an eidetic memory, then congratulations, you can pretty much ignore this section. However, if you are not blessed with such gifts, then we have many tricks and tips that allow you to make effective, high value notes. It is then only a short step to the creation of an essay plan that almost writes itself or arms you with the notes that allow you to enter the exam room with great confidence, which might be a new experience for some of you. We also list a tonne of different tools that you might want to use to further facilitate the process."
            }, 
            {
                title: "Chapter 4: An Unwasted plan",
                description: "During our university studies, we found that if students spent time constructing a decent essay plan rather trying to write ‘off the cuff’, it actually cut down the time taken to write the essay, improved the quality of writing and led to an overall increase in grade. If you know the direction you want to go and how you’ll get there before you set off, then there is little chance of getting lost, having to pull a 180 and go back on yourself or simply end up at a dead end with no finish in site. We’ll also touch on the structure and the building blocks of an essay, how to break these blocks down and arrange them to write almost any style of essay quickly and effectively."
            }   
        ]
    },
    {
        section: "Section 3: Getting Words on the Page",
        shortSection: "Getting words on the page",
        id: "section-3",
        icon: "fa fa-search",
        chapters: [
            {
                title: "Chapter 5: Essentials of Writing",
                description: "Writing is structured. Writing is best when there is variation in sentence length and complexity. Writing is helped by formats and standard practices that improve the readability and quality of your work. Here, we’ll look at the classic - “point, quote, explain” – a simple approach that is absent in many students’ work. We’ll delve into how to formulate an argument effectively and how to maintain a logical, consistent and valid argument throughout your essay. And, finally, we’ll look at some of the dos and don’ts of essay writing."
            }, 
            {
                title: "Chapter 6: Critical Editing",
                description: "Once you’ve written your essay, the last thing you want to do is read it again and again. Depending on your deadline, you may be lucky and be able to take a few hours away from staring at your words and come back to it with a fresh eye. Whatever your time frame, many authors admit that their books are made in the edit, forged in the fires of cuts, rewrites and restructuring. Whilst you may not be writing a book, even though a dissertation may feel like one, the same metaphor applies. If you’ve closely followed a well-structured plan, these edits will be minimal. If not, then there's every possibility a surgeon’s steady hand will be needed to cut away the superfluous words and distracting ideas. We highlight some of the tools available to help you edit and improve your essay."
            }   
        ]
    },
    {
        section: "Section 4: Preparing for Submission",
        shortSection: "Preparing for submission",
        id: "section-4",
        icon: "fa fa-stream",
        chapters: [
            {
                title: "Chapter 7: Proper Referencing",
                description: "Perhaps every student’s least favourite aspect of essay writing is the referencing section. We tackle this behemoth by dissecting the main types of referencing you will encounter: Harvard, APA, MLA and Chicago. Whilst we cover the majority of elements you may want to reference, very niche things - such as a particular podcast or earnings call – have been left out as they would take up far too much space and, quite frankly, we wouldn’t recommend doing it by hand anyway. We’ll give you a link to download cheat sheets for these giving you a quick way to implement the most common forms and point you towards tools that remove some of the effort and make referencing a significantly faster experience."
            }, 
            {
                title: "Chapter 8: Essay Presentation",
                description: "Finally, when your essay is complete, there is just one final task – preparing it for submission. A well-crafted essay needs to be presented properly and most universities have set standards or styles that you need to follow. Although they are all generally the same, it is worthwhile checking with your professors or having a quick look at your course guide to see if they highlight the specifics for your course or department. There are rumours of one or two people trying to hand in an essay written in the comic sans font… they were never heard from again."
            }   
        ]
    },
]