import React from 'react'
import Layout from '../components/layout/Layout';
import SEO from '../components/SEO';
import { IconSquare } from './index';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { bookContents } from '../data/Contents';
import Linked from '../components/elements/Linked';
import { BOOK_URL } from '../data/constants';
const contents = () => {
    return (
        <>
            <SEO title="Whats inside The Ultimate Essay Guide?" 
                description="A look at the contents of the Ultimate Essay Guide and a break down section by section of the broad tips, tricks and essay writing strategies you can expect within its crisp pages."
                image="https://theultimateessayguide.com/ueg-contents-cover-small.jpg"
            />
            <Layout className="leading-loose tracking-wide text-primary space-y-5 px-4">
                <section className="max-w-screen-md mx-auto py-16 " >

                    <IconSquare icon="fa fa-list" className="mx-auto w-24 h-24 mb-4" />
                    <h1 className="text-center my-2">Contents of The Ultimate Essay Guide</h1>
                    <p className="max-w-screen-md mx-auto lg:text-justify leading-loose tracking-wide">The Ultimate Essay Guide is split into 4 sections each with 2 chapters. This makes it easy to find the content you need and doesn’t require you to read the whole thing from cover to cover. If you want to learn more about how to write and structure arguments you can go to the writing section, or perhaps you want to learn effective referencing and reference management techniques. see the 4 sections below and click to jump to that part of the book or scroll down at your leisure. </p>


                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-12 my-12">
                        {

                            bookContents.map(({ shortSection, id, icon }) => <AnchorLink to={`/contents/#${id}`}>
                                <div className="flex w-full flex-col  ">
                                    <div className="mx-auto">
                                        <IconSquare icon={icon} bgColour="bg-white shadow-md" text="text-5xl text-primary" />
                                    </div>
                                    <div className="text-center self-center w-full my-4">
                                        <h3 className="mb-0 text-sm md:text-base font-normal md:px-0 px-4 ">{shortSection}</h3>
                                    </div>
                                </div>
                            </AnchorLink>)
                        }
                    </div>
                </section>


                {/* 
                    data stored locally for now
                
                
                
                */}
                {
                    bookContents.map(({ id, section, chapters }) => <section className="max-w-screen-md mx-auto space-y-4 mt-12 " id={id}>
                        <h2 className="text-3xl md:text-4xl mb-0">{section}</h2>

                        {chapters.map(({ title, description }) => <>
                            <h3 className="text-2xl md:text-3xl">{title}</h3>
                            <p className="">
                                {description}
                            </p>
                        </>)}
                    </section>)
                }


                <aside className="text-center w-full">
                    <Linked
                        linkTo={BOOK_URL}
                        className="text-xl w-max-c bg-gradient-l-golden-ripple hover:bg-gradient-l-golden inline-block text-primary rounded py-2 px-3 my-2">
                        Buy the book here
                  </Linked>
                </aside>



            </Layout>
        </>
    )
}

export default contents
