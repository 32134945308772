import React from 'react'
import PropTypes from 'prop-types'

const FAQ = ({questions=[]}) => {

    return (
        <dl className="max-w-3xl mx-auto mt-12 border-b border-gray-200 pb-6">
            { questions.map(question => <Question key={question.question} {...question}/>) }
        </dl>
    )
}


const Question = ({ question, answer }) => {

    const [expanded, setExpanded ] = React.useState(false)
    return         <div className="mt-6 border-t border-gray-200 pt-6">
        <dt className="text-lg leading-7">
            <button  onClick={() => setExpanded(!expanded)} className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                <h5 className="font-thin md:font-medium text-gray-900">
                   {question}
                </h5>
                <span className="ml-6 h-7 flex items-center">
                    <svg className={`${expanded ? "-rotate-180" : "rotate-0"} h-6 w-6 transform duration-200`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </span>
            </button>
        </dt>
        <dd className={`mt-2 pr-12 ${expanded ? "" : "-mt-2 opacity-0"} transform duration-200`}>
            <p className="text-base leading-6 text-gray-500" style={{height: !expanded ? "0px" : "100%" }} >
             {answer.text}
            </p>
        </dd>
    </div>
}

FAQ.propTypes = {

}

export default FAQ
